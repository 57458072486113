import React, { useContext, useState } from 'react'
import noteContext from '../../context/noteContext'

const ReviewItem = () => {

    const context = useContext(noteContext);
    const { reviews } = context;

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    return (
        <div>
            <div className="reviewcontainer " >
                <div className="row m-2">
                    {reviews.map((review, index) => {
                        return (
                            <div className="cardItem col-md-3 m-2 p-2 rounded"
                                key={index}>
                                <div className="p-2 rounded">
                                    <div className="card-body" >
                                        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                            <h5 className="card-text ">{review.name}</h5>
                                            <img src={require('../assets/google.png')} alt={'img of an investor'} width={'20px'} height={'20px'} style={{
                                                right: '0px',
                                                position: 'relative',
                                                borderRadius: '50px'
                                            }} />
                                        </div>
                                        <p className="card-text m-0"><small className="reviewDate">{formatDate(review.createdAt)}</small></p>
                                        <p className="card-text" >{isExpanded ? review.notes : review.notes.length > 150 ? review.notes.slice(0, 150) + '...' : review.notes}</p>
                                        {review.notes.length > 150 && (
                                            <button onClick={toggleExpand} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer', padding: '0 4px' }}>
                                                {isExpanded ? 'Read less' : 'Read more'}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </div>
    )
}

export default ReviewItem
