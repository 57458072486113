import React, { useContext, useRef } from 'react';
import noteContext from '../context/noteContext';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const context = useContext(noteContext);
  const { user, logoutUser } = context;

  let location = useLocation();
  const navbarCollapseRef = useRef(null);

  React.useEffect(() => {}, [location]);

  const handleNavLinkClick = () => {
    if (window.innerWidth < 1050 && navbarCollapseRef.current) {
      navbarCollapseRef.current.classList.remove('show');
    }
  };

  return (
    <div>
      <nav className="navbar fixed-top navbar-dark navbar-expand-lg fs-5 p-0">
        <div className="container-fluid text-align-center">
          {window.innerWidth < 1050 ?
            <Link to="/">
              <img src={require('./assets/logo.png')} width="58" height="55" alt="" />
            </Link> : 
            <>
              <Link to="/">
                <img src={require('./assets/logo.png')} width="60" height="60" alt="" />
              </Link>
              <Link className="navbar-brand fs-4 text-white" to="/" style={{marginLeft: ".5rem"}}>Global Trading Academy</Link>
            </>
          }
          <button className="navbar-toggler m-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{ border: "2px solid white" }}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent" ref={navbarCollapseRef}>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{ margin: "0 auto" }}>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/" onClick={handleNavLinkClick}>Home</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/Benefits' ? 'active' : ''}`} to="/Benefits" onClick={handleNavLinkClick}>Benefits</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === 'https://capitalxtend.com/calculators' ? 'active' : ''}`} to="https://capitalxtend.com/calculators" target='_blank' onClick={handleNavLinkClick}>Forex Calculator</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === 'About' ? 'active' : ''}`} to="/About" onClick={handleNavLinkClick}>About</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/Contacts' ? 'active' : ''}`} to="/Contacts" onClick={handleNavLinkClick}>Contact Us</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/Admin' ? 'active' : ''}`} style={{ display: user.isAdmin ? "block" : "none" }} to="/Admin" onClick={handleNavLinkClick}>Admin</Link>
              </li>
            </ul>

            <ul className="nav-login">
              {
                user ? (
                  <li>
                    <button className='btn btn-danger mx-2'>
                      <Link className="nav-link text-white" to="/" onClick={() => { handleNavLinkClick(); logoutUser(); }}>Logout</Link>
                    </button>
                  </li>
                ) : (
                  <>
                    <li>
                      <button className='btn btn-success mx-1'>
                        <Link className="nav-link text-white" to="/Login" onClick={handleNavLinkClick}>Login</Link>
                      </button>
                    </li>
                  </>
                )
              }
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
