import React from 'react'
import { Link } from 'react-router-dom'
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
    return (
        <>
            <footer className="footer navbar navbar-dark bg-dark">
                <div className='content-1'>
                    <ul className="social-menu">
                        <li className="footer-menu-items">
                            <FaInstagram className="mx-2 fs-5" />
                            <Link to="https://www.instagram.com/mr_shubham_choudhary/" target='blank' className="menu-items text-white">instagram</Link>
                        </li>
                        <li className="footer-menu-items">
                            <FaXTwitter className="mx-2 fs-5" />
                            <Link to="/" className="menu-items text-white">twitter</Link>
                        </li>
                    </ul>
                </div>
                <div className="content-2">
                        <p className='copyRight'>Copyright© 2024 Global Trading Academy. All Rights Reserved</p>
                </div>
                <div className="content-3">
                    <ul className="footer-menu">
                        <li className="footer-menu-items">
                            <Link to="/Disclaimer" className="menu-items text-white">Disclaimer</Link>
                        </li>
                        <li className="footer-menu-items">
                            <Link to="/PrivacyPolicy" className="menu-items text-white">Privacy policy</Link>
                        </li>
                    </ul>
                </div>
            </footer>
        </>
    )
}

export default Footer