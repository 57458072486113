import React, { useState } from 'react'

const Enrollmentform = () => {

    const [inForm, setInForm] = useState({
        name: "",
        email: "",
        phone: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { name, email, phone } = inForm;
            const response = await fetch('/api/addContact', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }, body: JSON.stringify({ name, email, phone }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                console.log("Successfully Enquiry submitted", data);
                setInForm({
                    name: "",
                    email: "",
                    phone: "",
                });
            } else {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        } catch (error) {
            console.log("error saving enquiry", error);
        }
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        setInForm(() => {
            return {
                ...inForm,
                [name]: value,
            };
        });
    };
    return (
        <div className='enrollContainer'>
            <main className='bothContainer '>
                <picture className='image-controller'>
                    <img src={require('./assets/enrollmentForm.jpg')} alt="Submit the details" height={'550px'} width={"100%"} />
                </picture>
                <section className='mainForm'>
                    <h1 className='mb-3 text-white'>You Are Just a Step Away!</h1>
                    <div className="enrollForm " style={{ width: "100%" }}>
                        <form className="myForm my-3" onSubmit={handleSubmit}>
                            <div className="name mb-3">
                                <input
                                    type="text"
                                    placeholder="Name"
                                    onChange={onChange}
                                    value={inForm.name}
                                    id="name"
                                    name="name"
                                />
                            </div>
                            <div className="email mb-3">
                                <input
                                    type="email"
                                    placeholder="Email"
                                    onChange={onChange}
                                    value={inForm.email}
                                    id="email"
                                    name="email"
                                />
                            </div>
                            <div className="phone mb-3">
                                <input
                                    type="number"
                                    placeholder="Phone"
                                    onChange={onChange}
                                    value={inForm.phone}
                                    name="phone"
                                    id="phone"
                                />
                            </div>

                            <div className="send mb-3 ">
                                <button className="text-center" type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Enrollmentform