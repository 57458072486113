import React from 'react'
import benefitsData from './BenefitsData'
import Footer from './Footer';

const Benifits = () => {

  
  //Generating unique modal id for each benefits Cards
  const getModalId = (index) => {
    return `exampleModal-${index}`;
  }

  return (
    <>
    <div className=" benefit container">
      <br/>
      <div className="row row-cols-3 my-2 mx-2">
      {benefitsData.map((benefits, index) => {
          const modalId = getModalId(index); //Generate unique modalId's
          return (
            <div className="col" key={benefits.a} >
              {/* Associate the unique modal ID with the button */}
              <div className="benefitsCard card text-bg-dark mb-5 bg-body-dark rounded" data-bs-toggle="modal" data-bs-target={`#${modalId}`}>
                <div className="card-body mainCard" >
                  <h3 className="card-title text-dark links align-middle" style={{ textAlign: 'center' }} >{benefits.a}</h3>
                  {/* ---------------------------------------------------------------- */}
                  {/* Use the unique modal ID for each modal */}
                  <div className="modal fade " id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                      <div className="modal-content bg-dark">
                        <div className="modal-header">
                          <h3 className="modal-title" id="exampleModalLabel">{benefits.a}</h3>
                          {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundColor: "wheat" }}></button> */}
                        </div>
                        <div className="modal-body" dangerouslySetInnerHTML={{ __html: benefits.links }} />
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------------------------------------------------------------- */}
                </div>
              </div>
            </div>
          )
        })
        }
      </div>
    </div>
    <Footer/>
      </>
  )
}

export default Benifits
