import React, { useContext, useState, useEffect } from 'react'
import noteContext from '../../context/noteContext'

const AddReview = () => {
  const context = useContext(noteContext);
  const { user, reviews, addReview, getReview } = context;

  useEffect(() => {
    getReview();
    // eslint-disable-next-line
  }, []);

  const [review, setReview] = useState({ name: "", description: "" });
  const [userData, setUserData] = useState(true);

  if (userData && user) {
    setReview({ name: user.name, description: "", createdAt: "" });
    setUserData(false);
  }

  //Checking the form before submitting
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, description } = review;
    if (name === '') {
      alert("Enter a valid name")
    }
    else if (name.length < 6) {
      alert("Name must be atleast 6 characters")
    } else if (description === '') {
      alert("Description must be written")
    } else if (description.length < 5) {
      alert("Description must be alteast 5 characters.")
    }
    else {
      addReview(user.name, review.description)
      setReview({ name: user.name, description: "" })
    }
  }

  //Changing the values
  const onChange = (e) => {
    setReview({ ...review, [e.target.name]: e.target.value })
  }

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand=()=>{
    setIsExpanded(true);
  }

  //convert standard date and time to string 
  const formatDate = (dateString)=>{
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2,'0');
    const month = String(date.getMonth()).padStart(2,'0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <><main className='singlePage'>
      <section className='container review-content ' >
        <h1 className='text-center text-decoration-underline' >Student Reviews</h1>
        {
          user ? <div className=' reviewForm'>
          <form onSubmit={handleSubmit} id='reviewCard' >
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name :</label>
              <input type="name" disabled className="form-control" name="text" id="name" value={review.name} onChange={onChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">Description :</label>
              <textarea className="form-control" name="description" id="description" onChange={onChange} value={review.description} rows="5" col="20" aria-label="With textarea" ></textarea>
            </div>
            <button type="submit" className="reviewBtn btn btn-primary" >Submit</button>
          </form>
        </div> : <p className='text-center'>Login to give reviews!...</p>
        }
      </section>
      <div className="reviewCard ">
            <div className="row m-2" style={{justifyContent: "center"}}>
              {reviews.map((review, index) => {
                return (
                  <div className="cardItem col-md-3 m-2 p-2 rounded"
                    key={index}>
                    <div className="p-2 rounded " >
                      <div className="card-body"  >
                        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                          <h5 className="card-text ">{review.name}</h5>
                          <img src={require('../assets/google.png')} alt={'faces of an investor'} width={'20px'} height={'20px'} />
                        </div>
                        <p className="card-text m-0"><small className="reviewDate">{formatDate(review.createdAt)}</small></p>
                        <p className="card-text m-1">{isExpanded ? review.notes : review.notes.length > 150 ? review.notes.slice(0, 150) + "..." : review.notes}<br /> </p>
                        {review.notes.length > 150 && (
                          <button onClick={toggleExpand} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer', padding: '0 4px' }}>
                            {isExpanded ? 'Read Less' : 'Read More'}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )
              }).slice(0, 3)
              }
              {reviews.length >= 5 &&
                <a href={"ReviewItem"} style={{ textDecoration: 'none', color: 'black' }} className="moreLinks col-md-3 m-2 p-2 rounded" >

                  <div className=" rounded" style={{ height: "9rem", display: "flex", alignItems: 'center' }}>
                    <div className="card-body fs-2 text-center" >
                      See more
                      <p className='fs-6 text-center'>Tap to see more reviews</p>

                    </div>
                  </div>
                </a>
              }
            </div>
          </div>
    </main>
    </>

  )
}

export default AddReview
