import React, { useState, useEffect } from "react";
import NoteContext from "./noteContext";

const NoteState = (props) => {

  //User Authentication

  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const DashboardValid = async () => {
    try {
      //token get from localstorage which is stored. reference-- 'login.js'
      setIsLoading(true);
      let token = localStorage.getItem("userDataToken");
      const response = await fetch("/api/getUser", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token
        }
      });

      if (response.ok) {
        const data = await response.json();
        // console.log(data.user);
        setUser(data.user);
        setIsLoading(false);
      }
      else {
        setIsLoading(false);
        console.log("Error fetching users ")
      }
    }
    catch (error) {
      console.log("Error fetching users " + error)
    }
  }

  // eslint-disable-next-line
  useEffect(() => {
    DashboardValid();
    getReview();
  }, [])

  //User Logged out..
  const logoutUser = async () => {

    try {
      let token = localStorage.getItem("userDataToken");
      const data = await fetch("/api/logoutUser", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
          accept: "application/json"
        },
        credentials: "include"
      });
      const response = await data.json();
      console.log(response);
      if (response.status === 200) {
        localStorage.removeItem("userDataToken")
        setUser(false);
      } else {
        console.log("Logged out Unsuccessfull")
      }
    } catch (error) {
      console.log(error)
    }

  }
  /*-----------------------------------------------------------------------------------------*/
  //Review Controller

  const reviewsInitial = []
  //getAllReview 
  const getReview = async () => {
    //API call
    try {
      const response = await fetch('/api/fetchAllReview', {
        method: 'GET',
        headers: {
          "Content-Type": 'application/json'
        }
      })
      const data = await response.json();
      // console.log(data);
      setReviews(data)
    }
    catch (error) {
      console.log('error fetching review', error);
    }
  };

  //addReview
  const addReview = async (name, notes, createdAt) => {
    //API call
    try {
      const response = await fetch('/api/addReview', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        }, body: JSON.stringify({ name, notes, createdAt })
      });

      const data = await response.json();
      console.log(data);
      console.log("Adding a new note");
      const newReview = {
        "name": name,
        "notes": notes,
        "createdAt": createdAt,
        "__v": 0
      };
      setReviews([...reviews, newReview]);
    }
    catch (error) {
      console.log("Error adding reviews", error)
    }
  }

  const [reviews, setReviews] = useState(reviewsInitial)
  return (
    <NoteContext.Provider value={{ user, DashboardValid, setUser, reviews, addReview, getReview, logoutUser, isLoading, }}>
      {props.children}
    </NoteContext.Provider>
  )
}

export default NoteState;