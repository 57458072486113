import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Contacts'

const Enroll = () => {
  const [msg, setMsg] = useState([]);
  const getMessages = async () => {
    try {
      const data = await fetch('/api/fetchAdminMessage', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })

      if (data.ok) {
        const response = await data.json();
        // console.log(response);
        //checks if theres atleast one message in the database.
          if(response.length > 0){
            setMsg(response[response.length-1].message);
          }else{
            setMsg("No message to Display");
          }

      } else {
        throw new Error(`HTTP error! Status: ${data.status}`);
      }
    }
    catch (error) {
      console.log("error fetching message", error);
    }
  }

  useEffect(() => {
    getMessages();
  }, [])

  return (
    <div>
      <div className='enroll my-2 '>
        <Link className='enrollLink mb-1' to='/Enrollmentform'>
          <button type="button" className="reviewBtn btn btn-primary" >Enroll</button>
        </Link>
        <span > REGISTER FOR FREE 1-1 APPOINTMENT </span>
      </div>
      {/* eslint-disable-next-line  */}
      <marquee className="info fs-5">
        <span>{msg}</span>
      </marquee>
    </div>
  )
}

export default Enroll
