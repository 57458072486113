import './App.css';
import Navbar from './components/Navbar';
import Benefits from './components/Benefits';
import Home from './components/Home';
import About from "./components/About";
import Login from './components/Login';
import Signup from './components/Signup';
import Error from "./components/Error"
import ReviewItem from './components/review/ReviewItem';
import NoteState from './context/NoteState';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import AdminLayout from './components/admin/AdminLayout';
import AdminUsers from './components/admin/AdminUsers';
import AdminReview from './components/admin/AdminReview';
import AdminEnquiry from './components/admin/AdminEnquiry';
import AdminUpdate from "./components/admin/Admin-Update";
import AdminView from './components/admin/Admin-View';
import Contacts from './components/Contacts';
import TradingPlatform from './components/Trading_Platform';
import AdminImpMessage from './components/admin/AdminImpMessage';
import PrivacyPolicy from './components/Privacy-policy';
import Disclaimer from './components/Disclaimer';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import Enrollmentform from './components/Enrollment-form';
import AdminContact from './components/admin/AdminContact';

function App() {
  return (
    <>
      <NoteState>
        <Router>
          <ScrollToTop />
          {/* <Title/> */}
          <Navbar />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <div >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Benefits" element={<Benefits />} />
              <Route path="/About" element={<About />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/Signup" element={<Signup />} />
              <Route path="*" element={<Error />} />
              <Route path="/ReviewItem" element={<ReviewItem />} />
              <Route path='/Contacts' element={<Contacts />} />
              <Route path='/TradingPlatform' element={<TradingPlatform />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/Disclaimer" element={<Disclaimer />} />
              <Route path="/Footer" element={<Footer />} />
              <Route path="/EnrollmentForm" element={<Enrollmentform />} />
              <Route path="/Admin" element={<AdminLayout />} >
                <Route path="AdminUsers" element={<AdminUsers />} />
                <Route path="AdminReview" element={<AdminReview />} />
                <Route path="AdminEnquiry" element={<AdminEnquiry />} />
                <Route path="AdminUsers/:id/edit" element={<AdminUpdate />} />
                <Route path="AdminEnquiry/:id/view" element={<AdminView />} />
                <Route path="AdminImpMessage" element={<AdminImpMessage />} />
                <Route path="AdminContact" element={<AdminContact />} />
              </Route>
            </Routes>
          </div>

        </Router>
      </NoteState>
    </>
  );
}

export default App;
