import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
  return (
    <>
      <main id="error">
        <div className='text-center'>
          <h1 >Error 404 Not Found!</h1>
          <Link to="/" className='text-white' >Go to homePage</Link>
        </div>
      </main>
    </>
  )
}

export default Error
