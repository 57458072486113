import React, { useState } from 'react'
import {Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './Login'

const Signup = () => {

  const [inVal, setInVal] = useState({
    name: '',
    email: '',
    password: ''
  })

  const history = useNavigate();

  const setVal = (e) => {
    const { name, value } = e.target;

    setInVal(() => {
      return {
        ...inVal, [name]: value
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password } = inVal;

    if (name === '') {
      toast.error('Enter Your name');
    } else if (email === '') {
      toast.error('Enter a valid Email');
    } else if (!email.includes("@")) {
      toast.error('Enter a valid Email');
    } else if (password === '') {
      toast.error('Enter your password');
    } else if (password.length < 6) {
      toast.error('Enter your password');
    } else {
      // console.log('Registration succesfully done');

      const data = await fetch('/api/register', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ name, email, password })
      });

      const response = await data.json();
      console.log(response);

      if (response.status === 200) {
        toast.success('User register successfull...');
        history("/login");
        setInVal({ ...inVal, name: "", email: "", password: "" });
      }

    }
  }

  return (
    <>
      <section>
        <main>
          <div className="signForm container grid grid-two-cols">
            <div className="image-controller">
              <img src={require('./assets/signup_img.jpg')} height={'520px'} width={"100%"} alt="Do SignUp tp move Ahead" />
            </div>
            <div className="myInfo">
              <div className="main-title">
                <h1>Registration Form</h1>
              </div>
              <form className='myForm my-3'>
                <div className="mb-4">
                  <label htmlFor="name" className="form-label">Enter Your Name:</label>
                  <input type="name" className=" form-control" placeholder='Enter your name' onChange={setVal} value={inVal.name} id="name" name="name" />
                </div>
                <div className="mb-4" >
                  <label htmlFor="email" className="form-label">Email address:</label>
                  <input type="email" className=" form-control" placeholder='Enter your Email' onChange={setVal} value={inVal.email} id="email" name="email"  />
                </div>
                <div className="mb-4">
                  <label htmlFor="password" className="form-label">Enter Your Password:</label>
                  <input type="password" className=" form-control" placeholder='password' onChange={setVal} value={inVal.password} name="password" id="password" />
                </div>
                <div className="text-center">
                  <button type="submit" className="signupBtn btn btn-primary " onClick={handleSubmit}> Submit</button>
                </div>
              </form>
              <div className="form-text m-2 text-white text-center">Already have an account. <Link to='/Login'>Login</Link></div>
            </div>
          </div>
        </main>
      </section>
    </>
  )
}

export default Signup

