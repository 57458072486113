import React from 'react'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {
    return (

        <>

            <div className='container'>

                <body>
                    <div className=' mb-5'>

                    <h1 className='text-center'>Global Trading Academy</h1>
                    <p className='text-center fs-3'> Terms & Conditions</p>
                    </div>

                    <h4>1. Agreement / Disclaimer / Terms & Conditions</h4>
                    <p>By accessing or participating in any services or programs offered by Global Trading Academy ("GTA"), you agree to comply with and be bound by the following terms and conditions. This policy outlines the use of your personal data, obligations, and other important legal aspects of your engagement with GTA. If you do not agree to these terms, please do not use our services.</p>

                    <h4>2. Participation</h4>
                    <p>Participation in GTA’s programs and services is subject to acceptance of these terms and conditions. Participants must provide accurate and complete information and adhere to the guidelines set forth by GTA. GTA reserves the right to modify or discontinue its services at any time without prior notice.</p>

                    <h4>3. Participant's Commitment to the Program</h4>
                    <p>Participants are expected to commit to the program, complete all required coursework, and actively engage in all training activities. Failure to meet these obligations may result in exclusion from the program without refund.</p>

                    <h4>4. Participant Conduct</h4>
                    <p>Participants must conduct themselves professionally and respectfully at all times. Harassment, discrimination, or any behavior deemed inappropriate by GTA will not be tolerated and may result in immediate removal from the program.</p>

                    <h4>5. Liability Disclaimer</h4>
                    <p>Global Trading Academy and its affiliates are not liable for any financial loss or damage resulting from participation in the program. All trading decisions and actions are the sole responsibility of the participant. GTA provides educational content and does not offer financial advice or guarantee returns.</p>

                    <h4>6. Confidentiality and Non-Compete</h4>
                    <p>Participants agree to keep confidential any proprietary or sensitive information obtained through GTA. Participants are prohibited from using GTA’s materials for competitive purposes or disclosing them to third parties without explicit permission.</p>

                    <h4>7. Audio / Visual Release</h4>
                    <p>Participants consent to the recording of their interactions during GTA sessions. These recordings may be used for promotional, educational, or training purposes. If you do not wish to be recorded, please notify GTA in advance.</p>

                    <h4>8. Cancellation Policy</h4>
                    <p>Participants may cancel their enrollment in the program according to the cancellation terms specified at the time of registration. Refunds, if applicable, will be processed in accordance with our refund policy, which is subject to change.</p>

                    <h4>9. Review and Jurisdiction</h4>
                    <p>This Privacy Policy is subject to periodic review and may be updated. The terms are governed by the laws of the jurisdiction where Global Trading Academy operates, and any disputes arising under these terms shall be resolved in the courts of that jurisdiction.</p>

                    <h4>10. Entire Agreement</h4>
                    <p>This Privacy Policy, together with any additional agreements and terms provided by GTA, constitutes the entire agreement between you and GTA regarding participation in our programs and services. It supersedes any prior agreements or understandings, whether written or oral.</p>

                    <p>For any questions or concerns regarding this Privacy Policy, please contact Global Trading Academy at 
                        <Link
                                            to="mailto:teamglobaltradingacademy@gmail.com"
                                            className="companyEmail"
                                         > Teamglobaltradingacademy@gmail.com</Link>.</p>

                </body>
            </div>
        </>
    )
}

export default PrivacyPolicy