import React from 'react'
import Enroll from "./Enroll";
import Contacts from './Contacts';
import AddReview from './review/AddReview';


const Home = () => {

  return (

    <div >
      <div className="image">
      </div>
      <div className="image">
        {window.innerWidth < 990 ? (
          <img src={require('./assets/1_mobile.jpg')} alt=" Shubham an investor" />
        ) : (
          <img src={require('./assets/1.jpg')} alt="Shubham an investor" />
        )}
      </div>
      <Enroll />
      <section className='section'>
        <main id="about">
          <div className='video-container' >
            <video autoPlay muted loop className='bg-video' type="video/mp4">
              <source src={require('./assets/bg-video.mp4')} type="video/mp4" />
            </video>
            <section className='container about-review'>
              <div >
                <h2 className='text-center'>About Global Trading Academy!</h2>
                <p className='fs-6'>"Welcome to the Global Trading Academy, the premier stock market institute in the Chhattisgarh Region! Whether you're a beginner or an experienced trader seeking to refine your skills, our comprehensive two-month courses cater to all levels—from fundamental concepts to advanced strategies. With our extensive experience and a proven track record, it’s clear why we’re the top choice for aspiring traders and investors. Enroll today to benefit from expert guidance and hands-on insights. Ready to transform your future with our stock market training? Contact us now and start your journey towards financial success!"
                </p>
              </div>
            </section>
          </div>
        </main>
        <AddReview />
      </section>
      <picture className="image "  >
        {
          window.innerWidth < 900 ? (
            <img src={require('./assets/2_mobile.jpg')} alt="img of an enterprener" style={{ margin: "10px 0 0 0", borderRadius: '15px 15px 0 0' }} />
          ) : (
            <img src={require('./assets/2.jpg')} alt="img of an enterprener" style={{ marginTop: "10px", borderRadius: '15px 15px 0 0' }} />
          )
        }
      </picture>
      <Contacts />
    </div >
  )
}

export default Home
