import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import noteContext from "../context/noteContext";
import Footer from "./Footer";
// import Footer from "";

const Contacts = () => {

    const context = useContext(noteContext)
    const { user } = context;

    const [userData, setUserData] = useState(true)
    const [inForm, setInForm] = useState({
        name: "",
        email: "",
        contactNumber: "",
        enquiry: "",
    });

    if (userData && user) {
        setInForm({
            name: "",
            contactNumber: "",
            email: "",
            enquiry: ""
        })
        setUserData(false)
    }

    const onChange = (e) => {
        const { name, value } = e.target;

        setInForm(() => {
            return {
                ...inForm,
                [name]: value,
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { name, email, contactNumber, enquiry } = inForm;
        const response = await fetch("/api/addEnquiry", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ name, email, contactNumber, enquiry }),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Successfully Enquiry submitted", data);
            setInForm({
                name: "",
                email: "",
                contactNumber: "",
                enquiry: "",
            });
        }
    };

    return (
        <div>
            <section className="section">
                <main className="singlePage">
                    <div>
                        <h1 className="text-center mt-4 text-decoration-underline">
                            Contact Us
                        </h1>
                        <div className="container" id="contacts">
                            <div className="contactForm">
                                <form className="myForm my-3">
                                    <div className="name mb-3">
                                        <input
                                            type="text"
                                            placeholder="Name"
                                            onChange={onChange}
                                            value={inForm.name}
                                            id="name"
                                            name="name"
                                        />
                                    </div>
                                    <div className="email mb-3">
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            onChange={onChange}
                                            value={inForm.email}
                                            id="email"
                                            name="email"
                                        />
                                    </div>
                                    <div className="phone mb-3">
                                        <input
                                            type="number"
                                            placeholder="Phone"
                                            onChange={onChange}
                                            value={inForm.contactNumber}
                                            name="contactNumber"
                                            id="contactNumber"
                                        />
                                    </div>
                                    <div className="enquiry mb-3">
                                        <input
                                            type="text"
                                            placeholder="Enquiry"
                                            onChange={onChange}
                                            value={inForm.enquiry}
                                            name="enquiry"
                                            id="enquiry"
                                        />
                                    </div>
                                    <div className="send mb-3">
                                        <button type="submit" onClick={handleSubmit}>Send</button>
                                    </div>
                                </form>
                            </div>
                            <div className="detailFrom" >
                                <div>
                                    <b className="heading">Global Trading Academy</b> <br /><p> Forex trading and Market Research</p>
                                </div>
                                <div>
                                    <p>
                                        <Link to="tel:7389150037" className="companyPhone" style={{ textDecoration: 'none', color: 'black' }}>
                                            <b>Contact: </b>7389150037
                                        </Link>
                                    </p>
                                    <p >
                                        <Link
                                            to="mailto:Teamglobaltradingacademy@gmail.com"
                                            className="companyEmail"
                                        >
                                            <b>Email: </b><span className="fs-6">Teamglobaltradingacademy@gmail.com</span>
                                        </Link>
                                    </p>
                                </div>
                                <p>
                                    <b>Address: </b>Comming Soon in Bilaspur, Chhattisgarh.
                                </p>
                                <div>
                                    <p className="fs-3">
                                        <b>For Career Opportunities</b>
                                        <br />
                                        <span className="fs-6">Contact us!</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </section>
            <Footer />

        </div>
    );
};

export default Contacts;
